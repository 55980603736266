.subtitle,
.btn-submit,
.timeline-title h1 {
  font-size: 14px;
}

.timeline-subtitle,
.timeline-title p {
  font-size: 12px;
}

.header-track,
.expired-title,
.highlight-timeline,
.container-track,
.timeline-title,
.timeline-title h1,
.timeline-subtitle .attachment {
  display: flex;
}

.header-track {
  font-size: 15px;
  font-weight: 800;
}

.subtitle,
.timeline-subtitle {
  margin: 10px 0 20px 0;
  font-size: 12px;
  position: relative;
}

.timeline-subtitle .time {
  position: absolute;
  right: 0;
  top: -2.6em;
}

.timeline-subtitle .attachment {
  gap: 10px;
  padding-top: 2px;
}

.btn-submit {
  padding: 8px 0 30px 0;
  margin: 15px 0;
}

.header-track,
.expired-title {
  gap: 15px;
}

.highlight-timeline {
  gap: 1.5em;
  height: 9em;
}

.container-track {
  flex-direction: column;
  gap: 1.8em;
  padding-bottom: 5em;
}

.timeline-title p {
  color: gray;
}
.timeline-font {
  margin-bottom: 20px;
}
.timeline-font h1 {
  font-size: 13.5px;
  margin-bottom: 10px;
  font-weight: 600;
}
.timeline-font p {
  font-size: 11.8px;
}

@media (max-width: 500px) {
  .timeline-title {
    width: 10em;
  }
}
