.navbar {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 9vh;
    box-shadow: -1px 2px 10px 0px rgba(0,0,0,0.75);
}
.tagline {
    color: #000;
    position: absolute;
    right: 2vh;
    font-size: 9px;
}
.icon-img {
    height: 3vh;
}
.version {
    font-style: italic;
}